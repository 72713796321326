import { API_BASE_URL } from "../config/constants";
import axios from "axios";

export default class BetsService {
  static getLootBetOdds() {
    const url = `${API_BASE_URL}/bets/lootBet`;

    return axios.get(url);
  }

  static getBetwayOdds() {
    const url = `${API_BASE_URL}/bets/betway`;

    return axios.get(url);
  }

  static getGGBetOdds() {
    const url = `${API_BASE_URL}/live-odds/ggbet`;

    return axios.get(url);
  }

  static getLiveOdds(providerKey) {
    const url = `${API_BASE_URL}/live-odds/${providerKey}`;

    return axios.get(url);
  }

  static getLiveOddsWithGameKey(providerKey, gameKey) {
    const url = `${API_BASE_URL}/live-odds/${providerKey}/${gameKey}`;

    return axios.get(url);
  }

  static async getParimatchLiveOdds({
    providerTournamentsIds,
    tournamentsIds,
    amount,
  }) {
    return { data: { data: [] } };

    const res = await axios.get(`${API_BASE_URL}/live-odds/parimatch`, {
      params: {
        tournamentsIds: tournamentsIds || [1303, 1302, 1301, 1300, 1299],
        providerTournamentsIds: providerTournamentsIds || [
          "214f02dce3514f789827dc00b41164c5",
          "760ddfb1d5964b1f8a6919557cbb523d",
          "8e6f547a3d144926b16784a83f62fab5",
          "dc69d402cbfa4153a56e8e38826fa637",
        ],
        amount,
      },
    });

    return res;
  }
}
