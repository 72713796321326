import React, { Component } from "react";
import styled from "styled-components";

// Components
import Card from "./Card";
import { colors } from "../../config/styles";

export default class EmptyState extends Component {
  render() {
    return (
      <EmptyStateContainer>
        <EmptyStateText>{this.props.text}</EmptyStateText>
        {this.props.children}
      </EmptyStateContainer>
    );
  }
}

const EmptyStateContainer = styled(Card)`
  text-align: center;
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.04), 0 5px 15px rgba(0, 0, 0, 0.1);
  color: ${colors.GRAY_LIGHT};
  width: 100%;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

const EmptyStateText = styled.p`
  margin-top: 0;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;
